import { useDomainContext } from "@hooks/useDomainContext";
import React, { ReactElement } from "react";
import styles from "./legal.module.scss";
import PrivacyPolicyFallback from "./fallbacks/privacyPolicyFallback";

export default function PrivacyPolicy({
    domainNameAsProp,
    categoryNameAsProp = "Auto Warranty and Car insurance",
    className,
    mainWrapperClassName,
}: {
    domainNameAsProp?: string;
    categoryNameAsProp?: string;
    className?: string;
    mainWrapperClassName?: string;
}): ReactElement {
    const {
        domain: { name: actualDomainName, niceName, textBlocks },
        category,
    } = useDomainContext();
    const domainName = niceName ?? actualDomainName ?? domainNameAsProp;
    const categoryName = category?.name ?? categoryNameAsProp;
    const replaceDomainName = (text: string) => {
        return text
            .replace(/{domainName}/g, domainName)
            ?.replace(/{categoryName}/g, categoryName)
            ?.replace(/{mailInfo}/g, domainName.toLowerCase());
    };
    const domainPrivacyPolicy = textBlocks?.find(
        (textBlock) => textBlock.position === "custom-domain-privacy-policy",
    )?.text;
    const categoryPrivacyPolicy = textBlocks?.find(
        (textBlock) => textBlock.position === "custom-category-privacy-policy",
    )?.text;

    return domainPrivacyPolicy || categoryPrivacyPolicy ? (
        <div
            className={`${mainWrapperClassName ?? styles["legal"]} ${
                className ?? ""
            }`}
            dangerouslySetInnerHTML={{
                __html: replaceDomainName(
                    (domainPrivacyPolicy ?? categoryPrivacyPolicy) as string,
                ),
            }}
        />
    ) : (
        <PrivacyPolicyFallback
            domainName={domainName}
            categoryName={categoryName}
            className={className}
            mainWrapperClassName={mainWrapperClassName}
        />
    );
}
